import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import optuneLogo from './assets/white_logo.png';
import backgroundLogo from './assets/logo_background.png';
import termsOfUse from './assets/optune_website_TOU.pdf';
import privacyPolicy from './assets/optune_website_privacy_policy.pdf';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';

// Home page component
const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPromo, setShowPromo] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [policyContent, setPolicyContent] = useState<string>('');
  const [policyTitle, setPolicyTitle] = useState<string>('');
  const [cookieConsentShown, setCookieConsentShown] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean | null>(null);
  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Always required
    analytics: false,
    marketing: false
  });
  const signupSectionRef = useRef<HTMLElement>(null);
  const valuePropositionRef = useRef<HTMLElement>(null);
  const [formData, setFormData] = useState({
    email: '',
    company: '',
    useCase: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    // Check for cookie consent in localStorage
    const hasConsent = localStorage.getItem('cookieConsent');
    const savedPreferences = localStorage.getItem('cookiePreferences');
    
    if (hasConsent) {
      setCookiesAccepted(hasConsent === 'true');
      setCookieConsentShown(true);
      
      if (savedPreferences) {
        try {
          setCookiePreferences(JSON.parse(savedPreferences));
        } catch (e) {
          console.error('Error parsing cookie preferences', e);
        }
      }
    } else {
      // Show cookie consent banner if no preference stored
      setCookieConsentShown(false);
    }

    // Preload images for smooth experience
    const images = [optuneLogo, backgroundLogo];
    Promise.all(images.map(image => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = reject;
      });
    }))
      .then(() => {
        setIsLoading(false);
        
        // Show promotional banner after a delay
        setTimeout(() => {
          setShowPromo(true);
        }, 1000); // Show after 1 second - showing it earlier since it's at the top
      })
      .catch(() => setIsLoading(false));

    // Prevent body scroll when modal is open
    if (isModalOpen || showCookieSettings) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen, showCookieSettings]);

  // Function to handle accepting all cookies
  const acceptAllCookies = () => {
    const allPreferences = {
      necessary: true,
      analytics: true,
      marketing: true
    };
    
    setCookiePreferences(allPreferences);
    setCookiesAccepted(true);
    setCookieConsentShown(true);
    localStorage.setItem('cookieConsent', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify(allPreferences));
    
    // Here you would initialize any tracking/analytics scripts
    // initializeTracking();
  };

  // Function to handle rejecting non-essential cookies
  const rejectNonEssentialCookies = () => {
    const minimalPreferences = {
      necessary: true,
      analytics: false,
      marketing: false
    };
    
    setCookiePreferences(minimalPreferences);
    setCookiesAccepted(false);
    setCookieConsentShown(true);
    localStorage.setItem('cookieConsent', 'false');
    localStorage.setItem('cookiePreferences', JSON.stringify(minimalPreferences));
  };

  // Function to save cookie preferences from settings modal
  const saveCookiePreferences = () => {
    setCookiesAccepted(cookiePreferences.analytics || cookiePreferences.marketing);
    setCookieConsentShown(true);
    localStorage.setItem('cookieConsent', 
      (cookiePreferences.analytics || cookiePreferences.marketing).toString());
    localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
    setShowCookieSettings(false);
    
    // Initialize or remove tracking based on preferences
    // if (cookiePreferences.analytics) { initializeAnalytics(); }
    // if (cookiePreferences.marketing) { initializeMarketing(); }
  };

  // Function to toggle cookie preferences
  const toggleCookiePreference = (type: 'analytics' | 'marketing') => {
    setCookiePreferences(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  // Function to handle scroll to next section
  const scrollToNextSection = () => {
    if (valuePropositionRef.current) {
      valuePropositionRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  // Function to handle scroll to features from the second scroll indicator
  const scrollToFeatures = () => {
    if (signupSectionRef.current) {
      signupSectionRef.current.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  // Setup message listener to know when form is submitted successfully
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Ensure message is from Google Forms domain
      if (event.origin.includes('google.com')) {
        if (event.data === 'formSubmitted') {
          setFormSubmitted(true);
          setIsSubmitting(false);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleModalClose = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      // Reset form when closing modal
      if (!formSubmitted) {
        setFormData({
          email: '',
          company: '',
          useCase: ''
        });
      }
    }
  };

  const openWaitlistModal = () => {
    setIsModalOpen(true);
    setFormSubmitted(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id === 'email' ? 'email' : id === 'company' ? 'company' : 'useCase']: value
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Get the form element and submit it to google (it has the proper action and method)
    if (formRef.current) {
      formRef.current.submit();
      
      // Google Forms doesn't always send a message back, so we set a fallback timer
      // to ensure users don't get stuck with the spinner
      setTimeout(() => {
        setFormSubmitted(true);
        setIsSubmitting(false);
      }, 3000);
    }
  };

  const openPrivacyPolicy = () => {
    window.open(privacyPolicy, '_blank');
  };

  const openTermsOfUse = () => {
    window.open(termsOfUse, '_blank');
  };

  const closeModal = () => {
    setShowPolicyModal(false);
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="App">
      {/* Promotional banner at the top */}
      {showPromo && (
        <div className="promo-banner">
          <div className="promo-content">
            <span className="promo-text">🔥 Early Access Available! Join our waitlist today to be the first to experience Optune!</span>
            <button 
              className="promo-button"
              onClick={openWaitlistModal}
            >
              Join Waitlist
            </button>
          </div>
          <button 
            className="promo-close"
            onClick={() => setShowPromo(false)}
            aria-label="Close promotion"
          >
            ×
          </button>
        </div>
      )}

      <div className="background-container">
        <img src={backgroundLogo} className="background-logo" alt="background" loading="lazy" />
      </div>
      <main className="landing-container">
        <div className="content-wrapper">
          <img
            src={optuneLogo}
            className="main-logo"
            alt="Optune Logo"
            loading="eager"
          />

          {/* Models Advantages Section */}
          <section className="models-advantages">
            <h1 className="main-heading">
              Small-specific models are <strong>better</strong>, <strong>faster</strong>, <strong>cheaper</strong>, <strong>more secure</strong> and most importantly, <strong>in your control</strong>.
            </h1>
            <div 
              className="scroll-indicator" 
              onClick={scrollToNextSection}
              role="button"
              tabIndex={0}
              aria-label="Scroll to next section"
            >
              <div className="scroll-arrow"></div>
              <div className="scroll-text">Learn how Optune helps</div>
            </div>
          </section>

          {/* Optune Value Proposition */}
          <section className="value-proposition" ref={valuePropositionRef}>
            <div className="features-grid">
              <div className="feature-card">
                <div className="feature-icon power-icon"></div>
                <h3>Seamless Transition to 1st-Party Models</h3>
                <p>
                  We enable companies to harness the power of open source models with the development speed and ease of commercial LLMs
                </p>
              </div>
              <div className="feature-card">
                <div className="feature-icon platform-icon"></div>
                <h3>Task-Specific Optimization</h3>
                <p>
                  Our API and platform convert task-specific prompts into task-specific models for a seamless transition from 3rd-party to 1st-party models
                </p>
              </div>
            </div>
            
            {/* Call to Action */}
            <div className="cta-container">
              <p className="cta-text">Stay in the Loop. Be the First to Know.</p>
              <div className="cta-button-container">
                <div className="arrow-right">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M19 12H5M12 19l-7-7 7-7"/>
                  </svg>
                </div>
                <button className="cta-button" onClick={openWaitlistModal}>
                  Join the Waitlist
                </button>
                <div className="arrow-left">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#3B82F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </div>
              </div>
            </div>
          </section>

          <div 
            className="scroll-indicator"
            onClick={scrollToFeatures}
            role="button"
            tabIndex={0}
            aria-label="Scroll to next section"
          >
            <div className="scroll-arrow"></div>
          </div>

          <section ref={signupSectionRef} className="signup-section">
            <h2>Stay in the Loop. Be the First to Know.</h2>
            <p>Sign up below to receive exclusive updates and early access when we launch.</p>

            <button
              className="signup-button"
              onClick={openWaitlistModal}
              aria-label="Join the Waitlist"
            >
              <span className="button-text">
                Join the Waitlist
                <span className="button-icon" aria-hidden="true"></span>
              </span>
              <span className="button-shine"></span>
            </button>
          </section>

          <section className="contact-section">
            <a
              href="mailto:info@optune.ai"
              className="contact-link"
              aria-label="Contact us via email"
            >
              Contact us: info@optune.ai
            </a>
          </section>

          {/* Footer Section */}
          <footer className="footer-section">
            <div className="footer-links">
              <Link to="/privacy_policy" className="footer-link">Privacy Policy</Link>
              <span className="footer-divider">|</span>
              <Link to="/terms_of_use" className="footer-link">Terms of Use</Link>
            </div>
            <div className="footer-copyright">
              © {new Date().getFullYear()} Optune, Inc. All rights reserved.
            </div>
          </footer>
        </div>
      </main>

      {/* Google Form Modal with a stunning design */}
      {isModalOpen && (
        <div
          className="modal-overlay"
          onClick={handleModalClose}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div
            className="modal-content"
            onClick={e => e.stopPropagation()}
          >
            <button
              className="modal-close"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close modal"
            >
              ×
            </button>
            <div className="modal-header">
              <h2 id="modal-title">Join the Waitlist</h2>
              <p>Be the first to experience Optune's revolutionary model technology</p>
            </div>
            <div className="form-container">
              {formSubmitted ? (
                <div className="success-message">
                  <div className="success-icon">✓</div>
                  <h3>Thank You for Joining!</h3>
                  <p>Thank you for joining the waitlist! We'll keep you in the loop about exciting updates and exclusive offers from optune.ai.</p>
                  <button 
                    className="close-button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              ) : (
                <>
                  {/* Hidden iframe to handle Google form submission */}
                  <iframe
                    ref={iframeRef}
                    name="hidden_iframe"
                    id="hidden_iframe"
                    style={{ display: 'none' }}
                    title="Google Form Submission Frame"
                    onLoad={() => {
                      // This will trigger when the iframe loads after form submission
                      // Only consider it a form submission if we're in submitting state
                      if (isSubmitting) {
                        setFormSubmitted(true);
                        setIsSubmitting(false);
                      }
                    }}
                  ></iframe>
                  
                  {/* Custom styled form that submits to Google Forms */}
                  <form 
                    ref={formRef}
                    className="custom-form" 
                    onSubmit={handleSubmit}
                    action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSddQVa7GOkkKATgWCaAz3CAA2OjVxC80UJjAL_LF-NihUtpZg/formResponse"
                    method="post"
                    target="hidden_iframe"
                  >
                    <div className="form-group">
                      <label htmlFor="email">What is your email?</label>
                      <input 
                        type="email" 
                        id="email" 
                        name="entry.744643131" // Updated Google form entry ID
                        placeholder="Your email" 
                        required 
                        className="form-input"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company">Company (optional)</label>
                      <input 
                        type="text" 
                        id="company" 
                        name="entry.412878903" // Updated Google form entry ID for company
                        placeholder="Your company name" 
                        className="form-input"
                        value={formData.company}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="use-case">What's your primary use case for small models? (optional)</label>
                      <textarea 
                        id="use-case" 
                        name="entry.2094245784" // Updated Google form entry ID for use case
                        placeholder="Tell us about your use case" 
                        className="form-textarea"
                        value={formData.useCase}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      ></textarea>
                    </div>
                    <button 
                      type="submit" 
                      className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="spinner"></span>
                      ) : (
                        <>
                          <span className="button-text">Submit</span>
                          <span className="button-icon">→</span>
                        </>
                      )}
                    </button>
                    <p className="form-footer">
                      We'll never share your information with third parties.
                    </p>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Privacy Policy Modal */}
      {showPolicyModal && (
        <div
          className="modal-overlay"
          onClick={closeModal}
          role="dialog"
          aria-modal="true"
          aria-labelledby="policy-title"
        >
          <div
            className="modal-content policy-modal"
            onClick={e => e.stopPropagation()}
          >
            <button
              className="modal-close"
              onClick={closeModal}
              aria-label="Close modal"
            >
              ×
            </button>
            <div className="policy-container">
              <h1 id="policy-title">{policyTitle}</h1>
              <div className="policy-content" dangerouslySetInnerHTML={{ __html: policyContent }}></div>
            </div>
          </div>
        </div>
      )}

      {/* Cookie Consent Banner */}
      {!cookieConsentShown && (
        <div className="cookie-consent-banner">
          <div className="cookie-content">
            <h3>We Value Your Privacy</h3>
            <p>
              We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. 
              <Link to="/privacy_policy" className="cookie-link">Read Our Privacy Policy</Link>
            </p>
            <div className="cookie-buttons">
              <button 
                className="cookie-button-secondary"
                onClick={() => setShowCookieSettings(true)}
              >
                Customize
              </button>
              <button 
                className="cookie-button-secondary"
                onClick={rejectNonEssentialCookies}
              >
                Reject Non-Essential
              </button>
              <button 
                className="cookie-button-primary"
                onClick={acceptAllCookies}
              >
                Accept All
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Cookie Settings Modal */}
      {showCookieSettings && (
        <div className="modal-overlay" onClick={() => setShowCookieSettings(false)}>
          <div className="modal-content cookie-settings-modal" onClick={e => e.stopPropagation()}>
            <button
              className="modal-close"
              onClick={() => setShowCookieSettings(false)}
              aria-label="Close cookie settings"
            >
              ×
            </button>
            <div className="cookie-settings-header">
              <h2>Cookie Settings</h2>
              <p>Manage your cookie preferences. Necessary cookies are required for the website to function properly.</p>
            </div>
            <div className="cookie-settings-options">
              <div className="cookie-option">
                <div className="cookie-option-header">
                  <div>
                    <h4>Necessary Cookies</h4>
                    <p>Required for the website to function properly. Cannot be disabled.</p>
                  </div>
                  <div className="cookie-toggle disabled">
                    <input 
                      type="checkbox" 
                      id="necessary-cookies" 
                      checked={true} 
                      disabled 
                    />
                    <label htmlFor="necessary-cookies"></label>
                  </div>
                </div>
              </div>
              <div className="cookie-option">
                <div className="cookie-option-header">
                  <div>
                    <h4>Analytics Cookies</h4>
                    <p>Help us improve our website by collecting anonymous usage information.</p>
                  </div>
                  <div className="cookie-toggle">
                    <input 
                      type="checkbox" 
                      id="analytics-cookies" 
                      checked={cookiePreferences.analytics} 
                      onChange={() => toggleCookiePreference('analytics')}
                    />
                    <label htmlFor="analytics-cookies"></label>
                  </div>
                </div>
              </div>
              <div className="cookie-option">
                <div className="cookie-option-header">
                  <div>
                    <h4>Marketing Cookies</h4>
                    <p>Used to track visitors across websites to display relevant advertisements.</p>
                  </div>
                  <div className="cookie-toggle">
                    <input 
                      type="checkbox" 
                      id="marketing-cookies" 
                      checked={cookiePreferences.marketing} 
                      onChange={() => toggleCookiePreference('marketing')}
                    />
                    <label htmlFor="marketing-cookies"></label>
                  </div>
                </div>
              </div>
            </div>
            <div className="cookie-settings-footer">
              <button 
                className="cookie-button-primary"
                onClick={saveCookiePreferences}
              >
                Save Preferences
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />
      </Routes>
    </Router>
  );
}

export default App;
