import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import optuneLogo from '../assets/white_logo.png';
import privacyPolicyPDF from '../assets/optune_website_privacy_policy.pdf';

const PrivacyPolicy: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string>('');
  
  useEffect(() => {
    // Set the PDF URL for the iframe
    setPdfUrl(privacyPolicyPDF);
  }, []);

  return (
    <div className="privacy-policy-page">
      <div className="privacy-policy-header">
        <Link to="/">
          <img src={optuneLogo} className="policy-logo" alt="Optune Logo" />
        </Link>
      </div>
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        
        {pdfUrl ? (
          <div className="pdf-container">
            <iframe 
              src={pdfUrl} 
              title="Privacy Policy" 
              className="pdf-viewer"
              width="100%"
              height="800px"
            />
          </div>
        ) : (
          <div className="loading">Loading Privacy Policy...</div>
        )}
        
        <div className="back-link">
          <Link to="/">Return to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 