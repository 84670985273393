import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import optuneLogo from '../assets/white_logo.png';
import termsOfUsePDF from '../assets/optune_website_TOU.pdf';

const TermsOfUse: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string>('');
  
  useEffect(() => {
    // Set the PDF URL for the iframe
    setPdfUrl(termsOfUsePDF);
  }, []);

  return (
    <div className="terms-page">
      <div className="terms-header">
        <Link to="/">
          <img src={optuneLogo} className="policy-logo" alt="Optune Logo" />
        </Link>
      </div>
      <div className="terms-container">
        <h1>Terms of Use</h1>
        
        {pdfUrl ? (
          <div className="pdf-container">
            <iframe 
              src={pdfUrl} 
              title="Terms of Use" 
              className="pdf-viewer"
              width="100%"
              height="800px"
            />
          </div>
        ) : (
          <div className="loading">Loading Terms of Use...</div>
        )}
        
        <div className="back-link">
          <Link to="/">Return to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse; 